<template>
  <div>
    <v-alert color="info" text border="top" class="text-center" dense>
      <v-avatar tile size="120">
        <v-img :src="require('@/assets/file.svg')"/>
      </v-avatar>
      <p class="fs-16">
        {{ description ? description[$lang] : null }}
      </p>
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    description: {
      Default: null
    }
  }
}
</script>

<style scoped>

</style>