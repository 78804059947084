<template>
  <div>

    <v-simple-table class="mt-5 table-border"
                    v-if="conditions.length">
      <template v-slot:default>
        <thead>
        <tr>
          <th>
            {{ $t('field.image') }}
          </th>
          <th>
            {{ $t('field.code') }}
          </th>
          <th>
            {{ $t('field.product') }}
          </th>
          <th>
            {{ $t('field.box') }}
          </th>
          <th>
            {{ $t('field.quantity') }}
          </th>
          <th class="text-right"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(condition,i) in conditions"
            :key="i">
          <td>

            <v-img v-if="condition.image"
                   :src="$fileUrl + condition.image"
                   width="40"
                   height="40"
                   class="zoom-pointer rounded"
                   @click="[image = [{src: $fileUrl + condition.image}],visible = true]"/>

            <v-img v-else
                   :src="require('@/assets/no-image.png')"
                   width="40"
                   height="40"/>

          </td>

          <td>{{ condition.code }}</td>
          <td>{{ condition.name }}</td>

          <td>
            <v-checkbox
                      class="ma-0 pa-0"
                      hide-details
                      v-model="condition.isBox"></v-checkbox>
          </td>

          <td>

            <v-btn fab x-small depressed
                   @click="decrementCondition(i)">
              <v-icon>mdi-minus</v-icon>
            </v-btn>

            {{ condition.quantity }}

            <v-btn fab x-small color="primary" depressed
                   @click="incrementCondition(i)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>

          </td>

          <td class="text-right">
            <v-btn icon @click="removeCondition(i)">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </td>

        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <vue-easy-lightbox escDisabled
                       moveDisabled
                       :visible="visible"
                       :imgs="image"
                       :index="index"
                       @hide="visible = false"></vue-easy-lightbox>

  </div>
</template>

<script>
export default {
  props: ['conditions'],
  data() {
    return {

      /**Lightbox**/
      image: '',
      visible: false,
      index: 0,
    }
  },
  methods: {
    decrementCondition(i) {
      if (this.conditions[i].quantity > 1)
        this.conditions[i].quantity -= 1
    },
    incrementCondition(i) {
      this.conditions[i].quantity += 1
    },
    removeCondition(i) {
      this.$delete(this.conditions, i)
    },
  }
}
</script>

<style scoped>

</style>