<template>
  <div>

    <draggable :options="{animation:300, handle:'.handle'}"
               v-model="currentOptions"
               @start="drag=true" @end="drag=false">

      <v-row v-for="(option,i) in currentOptions"
             :key="i"
             class="mb--25">
        <v-col v-show="$func.langNC('fr')">
          <v-text-field :label="$t('field.option') +' '+ (i+1)"
                        v-model="option.fr"
                        :prepend-inner-icon="answerKey ==='radio' ? 'mdi-radiobox-marked':'mdi-checkbox-marked'"
                        outlined dense
                        :hint="$t('hint.fr')"
                        persistent-hint
                        class="required"/>
        </v-col>
        <v-col v-show="$func.langNC('en')">
          <v-text-field :label="$t('field.option') +' '+ (i+1)"
                        v-model="option.en"
                        :prepend-inner-icon="answerKey ==='radio' ? 'mdi-radiobox-marked':'mdi-checkbox-marked'"
                        outlined dense
                        :hint="$t('hint.en')"
                        persistent-hint
                        class="required"/>
        </v-col>
        <v-col v-show="$func.langNC('ar')">
          <v-text-field :label="$t('field.option') +' '+ (i+1)"
                        v-model="option.ar"
                        :prepend-inner-icon="answerKey ==='radio' ? 'mdi-radiobox-marked':'mdi-checkbox-marked'"
                        outlined dense
                        :hint="$t('hint.ar')"
                        persistent-hint
                        class="required"/>
        </v-col>
        <v-col cols="2">

          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs"
                     icon
                     small
                     v-on="on" @click="dulicateQuestion(option)">
                <v-icon size="22">mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.duplicate') }}</span>
          </v-tooltip>

          <v-tooltip top color="primary" v-if="currentOptions.length > 1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs"
                     icon
                     small
                     v-on="on" @click="deleteQuestion(i)">
                <v-icon size="22">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.delete') }}</span>
          </v-tooltip>


          <v-tooltip top color="primary" v-if="currentOptions.length > 1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs"
                     class="handle"
                     icon
                     small
                     v-on="on">
                <v-icon size="22">mdi-drag-vertical</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.drag') }}</span>
          </v-tooltip>

        </v-col>
      </v-row>

    </draggable>

    <v-row>
      <v-col cols="12" class="text-center">
        <v-btn text block
               class="v-btn--active"
               color="blue"
               @click="addQuestion">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  props: ['options', 'answerKey'],
  data() {
    return {
      currentOptions: this.options,
    }
  },
  methods: {
    addQuestion() {
      this.$emit("add-question")
    },
    dulicateQuestion(option) {
      this.$emit("dulicate-question", option)
    },
    deleteQuestion(i) {
      this.$emit("delete-question", i)
    },
  },
  computed: {
    selectedOptions: {
      get() {
        return this.currentOptions
      },
      set(options) {
        this.currentOptions = options;
      }
    },
  },
  watch: {
    options(options) {
      this.selectedOptions = options
    },
    currentOptions(options) {
      this.$emit('update:options', options)
    },
  }
}
</script>

<style scoped>

</style>