<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>

      <ConfirmDialog ref="confirmDialog"/>

      <v-overlay :value="overlay"
                 :color="$vuetify.theme.dark?'secondary':'white'"
                 opacity="0.8"
                 :style="{zIndex: 100}">
        <v-layout justify-center align-center>
          <v-flex shrink>
            <v-img :src="require('@/assets/loading.svg')"
                   width="100"/>
          </v-flex>
        </v-layout>
        <p class="py-5 primary--text fs-17 font-weight-bold">
          {{ $t('message.waitingSave') }}
        </p>
      </v-overlay>


      <v-row justify="center">

        <v-col cols="12" lg="9">


          <FormValidation ref="formValidation"
                          :data.sync="campaign"
                          @loading="[isLoading=$event,btnLoading=$event]"
                          module="campaigns"
                          path="task"/>

          <CreateProgress :title="$t('campaign.title.key3')"
                          :progress="75"/>

          <InvestigationsList class="sticky-horizontal"
                              @investigationIndex="investigationIndex = $event"/>

          <draggable :options="{animation:300, handle:'.handle'}"
                     v-model="campaign.investigations[investigationIndex].tasks"
                     :force-fallback="true"
                     :scroll-sensitivity="200"
                     @start="drag=true"
                     @end="drag=false">

            <v-card class="rounded-lg shadow mb-5"
                    v-for="(task,i) in campaign.investigations[investigationIndex].tasks"
                    :key="i"
                    flat
                    :disabled="isLoading"
                    :loading="isLoading">

              <v-card-text>
                <v-row>
                  <v-col>
                    <QuestionTypes @set-question-type-id="task.questionTypeId = $event"
                                   :text-info="$t('info.questionType')"
                                   :question-type-id.sync="task.questionTypeId"/>
                  </v-col>
                  <v-col>
                    <AnswerTypes @set-answer-type-id="task.answerTypeId = $event"
                                 :text-info="$t('info.answerType')"
                                 :answer-type-id.sync="task.answerTypeId"/>
                  </v-col>
                </v-row>

                <v-row v-if="getQuestionTypeKey(task.questionTypeId) !== 'text'">
                  <v-col class="text-center">
                    <p class="fs-16 mt-5"> {{ getQuestionTypeDescription(task.questionTypeId) }} </p>
                    <IconUploadFile type="imagePdf"
                                    @file="task.file = $event"
                                    :path.sync="task.file"
                                    :index="i"
                                    :total="campaign.investigations[investigationIndex].tasks.length"/>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col v-show="$func.langNC('fr')">
                    <v-text-field :label="$t('field.question')"
                                  v-model="task.question.fr"
                                  :hint="$t('hint.fr')"
                                  persistent-hint
                                  class="required"/>
                  </v-col>
                  <v-col v-show="$func.langNC('en')">
                    <v-text-field :label="$t('field.question')"
                                  v-model="task.question.en"
                                  :hint="$t('hint.en')"
                                  persistent-hint
                                  class="required"/>
                  </v-col>
                  <v-col v-show="$func.langNC('ar')">
                    <v-text-field :label="$t('field.question')"
                                  v-model="task.question.ar"
                                  :hint="$t('hint.ar')"
                                  persistent-hint
                                  class="required"/>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-card outlined>

                      <v-card-text>

                        <Options
                            @add-question="addQuestion(i)"
                            @dulicate-question="dulicateQuestion($event,i)"
                            @delete-question="deleteQuestion($event,i)"
                            v-show="(getAnswerTypeKey(task.answerTypeId) ==='radio' && getQuestionTypeKey(task.questionTypeId) !== 'condition') || (getAnswerTypeKey(task.answerTypeId) ==='checkbox' && getQuestionTypeKey(task.questionTypeId) !== 'condition')"
                            :answer-key="getAnswerTypeKey(task.answerTypeId)"
                            :options.sync="task.options"/>

                        <File v-show="getAnswerTypeKey(task.answerTypeId) ==='file'"
                              :description="getAnswerTypeDescription(task.answerTypeId)"/>

                        <Photo v-show="getAnswerTypeKey(task.answerTypeId) ==='image'"
                               :description="getAnswerTypeDescription(task.answerTypeId)"/>

                        <Video v-show="getAnswerTypeKey(task.answerTypeId) ==='video'"
                               :description="getAnswerTypeDescription(task.answerTypeId)"/>

                        <Paragraph v-show="getAnswerTypeKey(task.answerTypeId) ==='paragraph'"
                                   :description="getAnswerTypeDescription(task.answerTypeId)"/>

                        <ShortParagraph v-show="getAnswerTypeKey(task.answerTypeId) ==='short_paragraph'"
                                        :description="getAnswerTypeDescription(task.answerTypeId)"/>

                        <Audio v-show="getAnswerTypeKey(task.answerTypeId) ==='audio'"
                               :description="getAnswerTypeDescription(task.answerTypeId)"/>

                        <Time v-show="getAnswerTypeKey(task.answerTypeId) ==='time'"
                              :description="getAnswerTypeDescription(task.answerTypeId)"/>

                        <Date v-show="getAnswerTypeKey(task.answerTypeId) ==='date'"
                              :description="getAnswerTypeDescription(task.answerTypeId)"/>

                        <NoResponse v-show="getAnswerTypeKey(task.answerTypeId) ==='none'"
                                    :description="getAnswerTypeDescription(task.answerTypeId)"/>

                        <div v-if="getQuestionTypeKey(task.questionTypeId) === 'condition'">

                          <Condition
                              @set-conditions="task.conditions = $event"
                              :conditionIds="task.conditions.map(el=>el.id)"
                              :conditions="task.conditions"
                          />

                          <ConditionList :conditions="task.conditions"/>

                        </div>


                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

              </v-card-text>
              <v-divider/>

              <v-card-actions>
                <v-btn color="blue" depressed
                       class="transform-none"
                       fab dark small>
                  {{ i + 1 }}
                </v-btn>

                <v-spacer/>

                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon
                           @click="duplicateTask(task)"
                           v-bind="attrs"
                           v-on="on">
                      <v-icon>mdi-content-duplicate</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.duplicate') }}</span>
                </v-tooltip>

                <v-tooltip bottom color="primary"
                           v-if="campaign.investigations[investigationIndex].tasks.length > 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon
                           @click="deleteTask(i)"
                           v-bind="attrs"
                           v-on="on">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.delete') }}</span>
                </v-tooltip>


                <v-tooltip top color="primary" v-if="campaign.investigations[investigationIndex].tasks.length > 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                           class="handle" icon
                           v-on="on">
                      <v-icon>mdi-drag-vertical</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.drag') }}</span>
                </v-tooltip>

              </v-card-actions>

            </v-card>

          </draggable>

          <div class="text-center">
            <v-tooltip bottom color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab depressed dark
                       color="blue"
                       small
                       @click="addTask"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.add') }}</span>
            </v-tooltip>
          </div>

          <v-row class="mt-1">
            <v-col>
              <v-btn text
                     color="primary"
                     depressed
                     to="/campaigns/create/investigations">
                <v-icon left>{{ $lang === 'ar' ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
                {{ $t('btn.back') }}
              </v-btn>
            </v-col>
            <v-spacer/>
            <v-col :class="'text-'+$float">
              <v-btn color="primary"
                     :disabled="isLoading"
                     :loading="btnLoading"
                     @click="validate"
                     type="submit"
                     depressed>
                {{ $t('btn.save') }}
                <v-icon right>mdi-content-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-col>
      </v-row>

      <ConfirmDialog ref="confirmDialog"/>

    </v-container>
  </div>
</template>

<script>
import InvestigationsList from "@/views/campaigns/create/components/InvestigationsList";
import File from "@/components/questionTypes/File";
import Photo from "@/components/questionTypes/Photo";
import Video from "@/components/questionTypes/Video";
import Options from "@/components/questionTypes/Options";
import Paragraph from "@/components/questionTypes/Paragraph";
import ShortParagraph from "@/components/questionTypes/ShortParagraph";
import Audio from "@/components/questionTypes/Audio";
import Time from "@/components/questionTypes/Time";
import Date from "@/components/questionTypes/Date";
import NoResponse from "@/components/questionTypes/NoResponse";
import ConditionList from "@/components/form/ConditionList";
import {HTTP} from "@/http-common";


export default {
  components: {
    Audio,
    Time,
    Date,
    NoResponse,
    ConditionList,
    Options,
    Video,
    Photo,
    File,
    Paragraph,
    ShortParagraph,
    InvestigationsList
  },
  data() {
    return {
      investigationIndex: 0,
      isLoading: false,
      btnLoading: false,
      overlay: false,
    }
  },
  methods: {
    async validate() {
      if (await this.$refs.formValidation.handleValidate()) {
        await this.handleSave()
      }
    },
    async handleSave() {
      if (await this.$refs.confirmDialog.open('', this.$i18n.t('message.confirmSaveCampaign'))) {
        this.overlay = true
        let campaign = this.$store.state.campaign.campaign

        HTTP.post('/campaigns/store', campaign).then(() => {
          this.overlay = false
          this.$Progress.finish()
          this.$successMessage = this.$i18n.t('message.succssDelete')
          this.$router.push('/campaigns')
        }).catch(err => {
          this.overlay = false
          this.$Progress.fail()
          this.$errorMessage = this.$i18n.t('message.catchError')
          console.log(err)
        })
      }
    },
    getAnswerTypeKey(id) {
      return this.answerTypes.find(el => el.id === id).key
    },
    getAnswerTypeDescription(id) {
      return this.answerTypes.find(el => el.id === id).description
    },
    getQuestionTypeKey(id) {
      return this.questionTypes.find(el => el.id === id).key
    },
    getQuestionTypeDescription(id) {
      return this.questionTypes.find(el => el.id === id).description[this.$lang]
    },
    /**Tasks***/
    addTask() {
      this.$store.commit('campaign/addTask', this.investigationIndex)
      this.$vuetify.goTo(document.body.scrollHeight - 250)
    },
    async deleteTask(i) {
      if (await this.$refs.confirmDialog.open()) {
        let data = {
          investigationIndex: this.investigationIndex,
          i
        }
        await this.$store.commit('campaign/deleteTask', data)
      }
    },
    duplicateTask(item) {
      let data = {
        investigationIndex: this.investigationIndex,
        data: item
      }
      this.$store.commit('campaign/duplicateTask', data)
      this.$vuetify.goTo(document.body.scrollHeight - 250)
    },

    /**Options***/
    addQuestion(taskIndex) {
      let data = {
        taskIndex,
        investigationIndex: this.investigationIndex
      }
      this.$store.commit('campaign/addQuestion', data)
    },
    dulicateQuestion(option, taskIndex) {
      let data = {
        taskIndex,
        option,
        investigationIndex: this.investigationIndex,
      }
      this.$store.commit('campaign/dulicateQuestion', data)
    },
    deleteQuestion(optionIndex, taskIndex) {
      let data = {
        taskIndex,
        optionIndex,
        investigationIndex: this.investigationIndex,
      }
      this.$store.commit('campaign/deleteQuestion', data)
    },
  },
  computed: {
    campaign() {
      return this.$store.state.campaign.campaign
    },
    answerTypes() {
      return this.$store.state.api.answerTypes
    },
    questionTypes() {
      return this.$store.state.api.questionTypes
    },
    breadcrumbs() {
      return [
        {to: '/campaigns', text: 'breadcrumb.campaigns'},
        {text: 'breadcrumb.create', disabled: true},
        {text: 'breadcrumb.infoPlanning', disabled: true},
        {text: 'breadcrumb.investigations', disabled: true},
        {text: 'breadcrumb.tasks', disabled: false},
      ]
    },
  }
}
</script>

<style scoped>

</style>