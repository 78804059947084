<template>
  <div>
    <v-card class="shadow-1 rounded-lg mb-4">
      <v-card-text>
        <v-slide-group show-arrows
                       v-model="investigationIndex"
                       mandatory>
          <v-slide-item
              :active-class="activeClass()"
              v-for="(item,i) in campaign.investigations"
              :key="i"
              :value="i"
              v-slot="{ active, toggle }">
            <v-btn class="transform-none text-none fs-15"
                   large
                   tile
                   text
                   @click="toggle">
              <span :class="$lang === 'ar' ? 'ar' : ''">
                {{ $func.setName(item.name, $store.state.campaign.campaign.lang) }}
              </span>
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      investigationIndex: 0
    }
  },
  methods: {
    activeClass() {
      return 'blue--transparent blue--text active--'
    },
  },
  computed: {
    campaign() {
      return this.$store.state.campaign.campaign
    }
  },
  watch: {
    investigationIndex() {
      this.$emit('investigationIndex', this.investigationIndex)
    }
  }
}
</script>

<style scoped>
.active-- {
  border-top: #2196F3 2px solid;
}
</style>